import { FC, lazy, LazyExoticComponent, Suspense } from 'react';
import { LoginCallback } from '@okta/okta-react';
import Error from 'components/error/Error';
import InlineLoading from 'components/inline-loading/InlineLoading';
import { OKTA_REDIRECT_URI } from 'config';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Routes as ReactRoutes, Route, RouteObject, useLocation } from 'react-router-dom';
import { RequiredAuth } from './SecureRoute';

export type Route = RouteObject & {
  path: string;
  name: string;
  Component: LazyExoticComponent<FC>;
  heading?: string;
};

export const AwsPageRoute = {
  path: '/aws',
  name: 'aws-accounts',
  Component: lazy(() => import('pages/aws-page/AwsPage')),
  heading: 'AWS Accounts',
};

export const DevPageRoute = {
  path: '/dev',
  name: 'development',
  Component: lazy(() => import('pages/dev-page/DevPage')),
  heading: 'Development',
};

export const DevApiTestingPageRoute = {
  path: '/dev/api-testing',
  name: 'dev-api-testing',
  Component: lazy(() => import('pages/dev-page/api-testing-page/ApiTestingPage')),
  heading: 'Dev API Testing',
};

export const DevJwtPageRoute = {
  path: '/dev/jwt',
  name: 'dev-jwt',
  Component: lazy(() => import('pages/dev-page/jwt-page/JwtPage')),
  heading: 'Dev JWT',
};

export const HomePageRoute = {
  path: '/',
  name: 'home',
  Component: lazy(() => import('pages/home-page/HomePage')),
  heading: 'Home',
};

export const NotFoundPageRoute = {
  path: '/404',
  name: 'not-found',
  Component: lazy(() => import('pages/not-found-page/NotFoundPage')),
  heading: '404',
};

export const privateRoutes: Route[] = [
  AwsPageRoute,
  DevPageRoute,
  DevApiTestingPageRoute,
  DevJwtPageRoute,
  HomePageRoute,
  NotFoundPageRoute,
];

export const Routing = () => {
  const location = useLocation();

  return (
    <ErrorBoundary
      key={location.pathname}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      FallbackComponent={({ error }: any) => <Error statusCode={error.statusCode} />}
    >
      <Suspense fallback={<InlineLoading size={64} />}>
        <ReactRoutes>
          {/* Public routes */}
          <Route
            path={OKTA_REDIRECT_URI}
            element={<LoginCallback errorComponent={() => <Error />} loadingElement={<InlineLoading />} />}
          />

          {/* Private routes */}
          {privateRoutes.map(({ path, Component }, key) => (
            <Route key={key} path={path} element={<RequiredAuth />}>
              <Route path="" element={<Component />} />
            </Route>
          ))}
          <Route path="*" element={<Navigate replace to="/404" />} />
        </ReactRoutes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Routing;
