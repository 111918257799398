export const API_URL = process.env.REACT_APP_API_URL as string;
export const APP_BUILD = process.env.REACT_APP_BUILD as string;
export const APP_VERSION = process.env.REACT_APP_VERSION as string;

export const CONTROL_GROUP_DEV = process.env.REACT_APP_CONTROL_GROUP_DEV as string;

export const DATADOG_APPLICATION_ID = process.env.REACT_APP_DATADOG_APPLICATION_ID as string;
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string;
export const DATADOG_SESSION_SAMPLE_RATE: number =
  Number(process.env.REACT_APP_DATADOG_SESSION_SAMPLE_RATE as string) || 0;
export const DATADOG_SITE = process.env.REACT_APP_DATADOG_SITE as string;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string;
export const EXTERNAL_DOCS_URL = process.env.REACT_APP_EXTERNAL_DOCS_URL as string;
export const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID as string;
export const OKTA_ISSUER = process.env.REACT_APP_OKTA_ISSUER as string;
export const OKTA_REDIRECT_URI = process.env.REACT_APP_OKTA_REDIRECT_URI as string;
export const SERVICE = process.env.REACT_APP_SERVICE as string;

export const SUPPORT_CHANNELS = {
  email: process.env.REACT_APP_SUPPORT_EMAIL as string,
  slack: {
    channel_name: process.env.REACT_APP_SUPPORT_SLACK_CHANNEL as string,
    channel_url: process.env.REACT_APP_SUPPORT_SLACK_URL as string,
  },
};
