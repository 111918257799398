import { StorageType } from '@okta/okta-auth-js';
import { CONTROL_GROUP_DEV, ENVIRONMENT, OKTA_CLIENT_ID, OKTA_ISSUER, OKTA_REDIRECT_URI } from 'config';

// Configure TokenManager to use sessionStorage instead of localStorage
const oktaStorageConfig: StorageType = 'sessionStorage';

export const oktaAuthConfig = {
  devMode: ENVIRONMENT === 'development' ? true : false,
  clientId: OKTA_CLIENT_ID,
  issuer: OKTA_ISSUER,
  pkce: true,
  redirectUri: `${window.location.origin}${OKTA_REDIRECT_URI}`,
  scopes: ['openid', 'profile', 'email', 'offline_access', 'groups'],
  uniq: Date.now() + Math.round(Math.random() * 1000), // to guarantee a unique state
  services: {
    autoRenew: true, // Attempt refresh in background before token expires
    autoRemove: true, // Remove stale tokens that cannot be refreshed
    syncStorage: true, // Keep token storage synced between browser tabs
  },
  storageManager: {
    token: {
      storageType: oktaStorageConfig,
    },
    cache: {
      storageType: oktaStorageConfig,
    },
    transaction: {
      storageType: oktaStorageConfig,
    },
  },
  tokenManager: {
    storage: oktaStorageConfig,
  },
  controlGroups: {
    developer: CONTROL_GROUP_DEV,
  },
};
